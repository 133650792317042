<template>
  <div id="app">
    <!-- Include HeaderComponent here -->
    <!--<HeaderComponent />-->

    <PhotoGrid />

    <!-- Include FooterComponent here -->
    <!--<FooterComponent />-->
  </div>
</template>

<script>
import PhotoGrid from './components/PhotoGrid.vue';

export default {
  name: 'App',
  components: {
    PhotoGrid,
    // Uncomment the following lines when HeaderComponent and FooterComponent are ready
    // HeaderComponent,
    // FooterComponent
  },
};
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

