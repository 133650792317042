<template>
  <div class="grid">
    <div
      v-for="photo in randomPhotos"
      :key="photo.UID"
      class="grid-item"
    >
      <img :src="photo.Thumbs.fit_720.src" :alt="photo.Title" :width="photo.Thumbs.fit_720.w" :height="photo.Thumbs.fit_720.h" />
      <h3>{{ photo.Title }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      photos: [],
      randomPhotos: [],
    };
  },
  async mounted() {
    try {
      const accessToken = process.env.REACT_APP_ALBUM_ACCESS_TOKEN;
      const authResponse = await fetch('/api/v1/session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: accessToken }),
      });

      const authData = await authResponse.json();
      const sessionId = authData.id;

      const url = new URL('/api/v1/photos/view', window.location.origin);
      url.searchParams.append('count', 8);
      url.searchParams.append('offset', 0);
      // Add other required URL parameters

      const photoResponse = await fetch(url, {
        headers: {
          'x-session-id': sessionId,
        },
      });

      const photoData = await photoResponse.json();
      this.photos = photoData;
      this.randomPhotos = this.getRandomPhotos(this.photos, 8);
    } catch (error) {
      console.error('Failed to fetch photos:', error);
    }
  },
  methods: {
    getRandomPhotos(photos, count) {
      const shuffled = photos.sort(() => 0.5 - Math.random());
      return shuffled.slice(0, count);
    },
  },
};
</script>

<style>
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
}

.grid-item {
  margin-bottom: 1rem;
}
</style>

